import React from 'react';
import { useServices } from 'services/data';
import './ServiceColumns.scss';

function ServiceColumns() {
  const services = useServices();

  const columns = services.map((service) => (
    <div className="service column is-one-third" key={service.title}>
      <div className="image">
        <img src={service.image} alt={service.title} />
      </div>
      <h4 className="title is-4">{service.title}</h4>
      <p>{service.description}</p>
    </div>
  ));

  return (
    <div id="service-columns" className="container">
      <h2 className="section-title center">Our Services</h2>
      <div className="subline">
        <p>
          OilDrop International aims to offer the best services in the industry
          at the most reasonable prices, while fervently pursuing our vision of
          being Eco Friendly for the preservation of our environment for future
          generations.
        </p>
      </div>
      <div className="columns">{columns}</div>
    </div>
  );
}

export default ServiceColumns;
