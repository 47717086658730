import React from 'react';
import './ImageSeparator.scss';

function ImageSeparator() {
  return (
    <div id="image-separator">
      <div className="wrap">
        Investing in our future.
        <br />
        Today.
        <br />
        Together.
      </div>
    </div>
  );
}

export default ImageSeparator;
