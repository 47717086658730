import React from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import DOMPurify from 'dompurify';
import './Post.scss';

interface PostProps {
  title: string;
  image: string;
  date: Date;
  content: string;
  slug: string;
}

function Post({ title, image, date, content, slug }: PostProps) {
  let excerpt = content.substr(0, 330);
  excerpt = excerpt.substr(0, excerpt.lastIndexOf(' '));
  if (content.length > excerpt.length) excerpt += ' ...';

  return (
    <span className="blog-post">
      <div className="columns">
        <div className="column is-one-third">
          <Link to={'/blog/' + slug} role="figure" className="image">
            <img src={image} alt={title} />
          </Link>
        </div>
        <div className="column is-two-thirds">
          <Link to={'/blog/' + slug} className="title">
            <h3>{title}</h3>
          </Link>
          <p className="date">{moment(date).format('MMM D, YYYY')}</p>
          <p>{DOMPurify.sanitize(excerpt)}</p>
          <Link to={'/blog/' + slug} className="more">
            Read More
          </Link>
        </div>
      </div>
      <hr />
    </span>
  );
}

export default Post;
