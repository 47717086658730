import React, { useState } from 'react';
import ScrollToTop from 'components/ScrollToTop';
import PageHeader from 'components/PageHeader';
import ContactForm from './components/ContactForm';
import SocialIcons from 'components/SocialIcons';
import Branch from './components/ContactForm/Branch';
import './Contact.scss';

const branches = [
  {
    default: true,
    key: 'jhb',
    name: 'Gauteng Region',
    contact: 'Andre Greyvensteyn',
    tel: '064 798 7363',
    email: 'ag@oildrop.co.za',
    address: '25 Leicester Street, Benoni, Gauteng',
  },
  {
    key: 'wc',
    name: 'Western Cape',
    contact: 'Jade Mostert',
    tel: '068 062 6078',
    email: 'development@oildrop.co.za',
    address: '4 Winning Way, Milnerton, Western Cape',
  },
  {
    key: 'kzn',
    name: 'Kwa-Zulu Natal',
    contact: 'David McClenaghan',
    tel: '072 472 1745',
    email: 'gw@oildrop.co.za',
    address: '6 Rinaldo Park, 50 Moreland Drive, Redhill, KZN',
  },
  {
    key: 'ec',
    name: 'Eastern Cape',
    contact: 'Shaun Tuck',
    tel: '072 060 4981',
    email: 'pe.collections@oildrop.co.za',
    address: 'Unit 1, Wren Road, New Industrial Township, PE',
  },
];

function Contact() {
  const [activeBranch, setActiveBranch] = useState(branches[0].key);

  const branchList = branches.map((branch) => (
    <Branch
      key={branch.key}
      show={branch.key === activeBranch}
      name={branch.name}
      contact={branch.contact}
      tel={branch.tel}
      email={branch.email}
      address={branch.address}
      onClick={() => setActiveBranch(branch.key)}
    />
  ));

  return (
    <div id="contact">
      <ScrollToTop />
      <PageHeader title="Contact Us" subtitle="Get in touch with us" />
      <div className="container">
        <div className="columns">
          <div className="column is-one-third">
            <SocialIcons />
            {branchList}
          </div>
          <div className="column is-two-thirds">
            <ContactForm />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Contact;
