import React from 'react';
import './ServiceSeparator.scss';

function ServiceSeparator() {
  return (
    <div id="service-separator">
      <div className="wrap">
        Industry Leaders in Fast, Reliable UCO Collection &#38; Management.
      </div>
    </div>
  );
}

export default ServiceSeparator;
