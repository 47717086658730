import React from 'react';
import MissionSVG from 'assets/svg/mission.svg';
import VisionSVG from 'assets/svg/vision.svg';
import ValuesSVG from 'assets/svg/values.svg';
import './CompanyValues.scss';

const values = [
  {
    image: MissionSVG,
    title: 'Mission',
    text: 'Our mission is to take cooking oil full circle. We are conscious believers in being part of the solution when it comes to sustainable, renewable resources and therefore recycling and innovation are a part of the process. We are committed to supporting all efforts to achieve this highly important objective of reducing the global carbon footprint. \n\nWe are invested in having our clients be part of the solution and participate in this cause. We want to urge our clients to help the environment by allowing us to collect their used cooking oil. By helping the environment, we help our clients. We provide certifications to prove their participation in the process of creating renewable resources and the best rebates in the market.',
  },
  {
    image: VisionSVG,
    title: 'Vision',
    text: 'Serving the Industry and Community that Serves us! We are acutely aware of all the role players from “Seed to Harvest". It is now our proud and passionate vision to add "Store to Restore" to this vision too. We are invested in restoring balance through Innovation, Recycling, Renewable Resources, Job Creation, Market support through fair rebates, and New Business Opportunities. \n\nIn addition, we are devoted to Education and Community Upliftment programs with the hopes of involving communities to join us in the pursuit of our vision which impacts the future for each of us. Exciting projects will be unveiled in the near future Quite simply, we\'re aiming at paying it forward in every sphere of the industry.',
  },
  {
    image: ValuesSVG,
    title: 'Values',
    text: "We are led by a strong management team for team. Our exceptional management team is single-minded in adding value to the lives of each and every person in our pursuit of growth through Equal Opportunities for all. Therefore, we like to consider Team Oil Drop as our family of colleagues and would like to include our clients as well. \n\nWe place the highest value on our relationships with our colleagues and clients and so we're mindful of our determination in taking of and growing them to their fullest potential. Our holistic approach in supporting our staff &amp; clients will hopefully ripple through to their families, staff, and clients too.",
  },
];

function CompanyValues() {
  const columns = values.map((item) => (
    <div className="column is-one-third" key={item.title}>
      <img src={item.image} alt={`Our ${item.title}`} />
      <h2 className="section-title center">{item.title}</h2>
      <p>{item.text}</p>
    </div>
  ));

  return (
    <div id="company-values">
      <div className="container">
        <div className="columns">{columns}</div>
      </div>
    </div>
  );
}

export default CompanyValues;
