import React from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import './RecentPost.scss';

interface RecentPostProps {
  title: string;
  image: string;
  date: Date;
  content: string;
  slug: string;
}

function RecentPost({ title, image, date, content, slug }: RecentPostProps) {
  let excerpt = content.substr(0, 140);
  excerpt = excerpt.substr(0, excerpt.lastIndexOf(' '));
  if (content.length > excerpt.length) excerpt += ' ...';

  return (
    <div id="recent-post" className="column is-one-third post">
      <Link to={'/blog/' + slug} role="figure" className="image">
        <img src={image} alt={title} />
      </Link>
      <p className="date">{moment(date).format('MMMM DD, YYYY')}</p>
      <Link to={'/blog/' + slug}>
        <h3 className="title">{title}</h3>
      </Link>
      <p className="excerpt">{excerpt}</p>
    </div>
  );
}

export default RecentPost;
