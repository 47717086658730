import React from 'react';
import { useParams } from 'react-router-dom';
import { usePostBySlug } from 'services/Posts';
import moment from 'moment';
import DOMPurify from 'dompurify';
import './BlogPost.scss';

function BlogPost() {
  const { slug } = useParams();
  const service = usePostBySlug(slug);

  return (
    <div id="blog-post">
      {service.status === 'loading' && <p>Loading...</p>}
      {service.status === 'error' && (
        <p>An error occured: {service.error.message}</p>
      )}
      {service.status === 'loaded' && (
        <>
          <div
            className="post-header"
            style={{
              background: `linear-gradient(rgba(43, 66, 80, .8), rgba(34, 52, 63, .8)), url(${process.env.REACT_APP_API_URL}${service.payload.thumbnail.url})`,
              backgroundAttachment: 'fixed',
              backgroundPosition: 'bottom',
              backgroundSize: 'cover',
            }}
          >
            <div className="container">
              <h2>{service.payload.title}</h2>
              <h5>{moment(service.payload.createdAt).format('MMM D, YYYY')}</h5>
            </div>
          </div>
          <div className="content">
            <div className="container">
              <div className="columns">
                <div className="column is-two-thirds">
                  <p>{DOMPurify.sanitize(service.payload.content)}</p>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default BlogPost;
