import React from 'react';
import ContentLoader from 'react-content-loader';

export const RecentPostsLoader = () => (
  <ContentLoader viewBox="0 0 430 430">
    <rect x="0" y="0" rx="5" ry="5" height="270" width="430" />
    <rect x="0" y="295" rx="5" ry="5" height="11" width="120" />
    <rect x="0" y="314" rx="5" ry="5" height="24" width="220" />
    <rect x="0" y="360" rx="5" ry="5" height="14" width="430" />
    <rect x="0" y="382" rx="5" ry="5" height="14" width="430" />
    <rect x="0" y="406" rx="5" ry="5" height="14" width="310" />
  </ContentLoader>
);
