import { Mail, MailBodyData } from 'types/Mail';

enum Message {
  Booking = 'You have received a new booking request on the OilDrop website.',
  Contact = 'You have received a new message from the OilDrop website contact form.',
}

const generateEmails = (
  bodyData: MailBodyData,
): { text: string; html: string } => {
  const data = Object.keys(bodyData).map(
    (key) => `${key}: ${bodyData[key]}\r\n`,
  );
  const text = `${
    bodyData.type === 'booking' ? Message.Booking : Message.Contact
  }\r\n\r\n ${data.join(' ')}`;
  const html = `<p>${
    bodyData.type === 'booking' ? Message.Booking : Message.Contact
  }</p><hr/>${data.map((val) => `<p>${val}</p>`).join('')}<hr/>`;

  return { text, html };
};

export const sendMail = ({
  to,
  from,
  subject,
  body,
}: Mail): Promise<Boolean> => {
  return fetch(process.env.REACT_APP_API_URL + '/email', {
    method: 'POST',
    headers: new Headers({
      'Content-Type': 'application/json',
    }),
    body: JSON.stringify({
      to,
      from,
      subject,
      ...generateEmails(body),
    }),
  })
    .then((res) => res.json())
    .then((data) => {
      return data;
    });
};
