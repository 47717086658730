import React from 'react';
import ScrollToTop from 'components/ScrollToTop';
import PageHeader from 'components/PageHeader';
import BlogPost from './components/Post';
import { usePosts } from 'services/Posts';
import './Blog.scss';

function Blog() {
  const service = usePosts();

  const posts =
    service.status === 'loaded' &&
    service.payload.map((post, idx) => (
      <BlogPost
        key={idx}
        title={post.title}
        image={process.env.REACT_APP_API_URL + post.thumbnail.url}
        date={post.createdAt}
        content={post.content}
        slug={post.slug}
      />
    ));

  return (
    <div id="blog">
      <ScrollToTop />
      <PageHeader title="Blog" subtitle="Our latest news and projects" />
      <div className="content">
        <div className="container">
          {service.status === 'loading' && <p>Loading...</p>}
          {service.status === 'error' && (
            <p>An error occured: {service.error.message}</p>
          )}
          {service.status === 'loaded' && posts}
        </div>
      </div>
    </div>
  );
}

export default Blog;
