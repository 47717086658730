import React from 'react';
import ScrollToTop from 'components/ScrollToTop';
import Banner from './components/Banner';
import Brands from './components/Brands';
import CallToAction from 'components/CallToAction';
import AboutOilDrop from './components/AboutOilDrop';
import ImageSeparator from './components/ImageSeparator';
import CompanyValues from './components/CompanyValues';

function About() {
  return (
    <div id="about">
      <ScrollToTop />
      <Banner />
      <Brands />
      <AboutOilDrop />
      <ImageSeparator />
      <CompanyValues />
      <CallToAction />
    </div>
  );
}

export default About;
