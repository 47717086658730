import React from 'react';
import { createRoot } from 'react-dom/client';
import TagManager from 'react-gtm-module';

import 'assets/scss/main.scss';
import App from 'layouts/App';

import * as serviceWorker from 'services/serviceWorker';

const container = document.getElementById('root');
const root = createRoot(container!);

TagManager.initialize({
  gtmId: process.env.REACT_APP_GTM_ID as string,
});

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
