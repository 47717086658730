import React from 'react';
import { ReactComponent as WimpyLogo } from 'assets/svg/wimpy.svg';
import { ReactComponent as McdonaldsLogo } from 'assets/svg/mcdonalds.svg';
import { ReactComponent as KFCLogo } from 'assets/svg/kfc.svg';
import { ReactComponent as SteersLogo } from 'assets/svg/steers.svg';
import { ReactComponent as BurgerKingLogo } from 'assets/svg/burgerking.svg';
import { ReactComponent as NandosLogo } from 'assets/svg/nandos.svg';
import './Brands.scss';

function Brands() {
  return (
    <div id="brands">
      <div className="container">
        <ul>
          <li>
            <WimpyLogo id="wimpy" />
          </li>
          <li>
            <McdonaldsLogo id="mcdonalds" />
          </li>
          <li>
            <KFCLogo id="kfc" />
          </li>
          <li>
            <SteersLogo id="steers" />
          </li>
          <li>
            <BurgerKingLogo id="burger-king" />
          </li>
          <li>
            <NandosLogo id="nandos" />
          </li>
        </ul>
      </div>
    </div>
  );
}

export default Brands;
