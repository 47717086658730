import React, { useState } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { object, string } from 'yup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelopeOpen } from '@fortawesome/free-solid-svg-icons';
import { sendMail } from 'services/Mail';
import Notification, { NotificationType } from 'components/Notification';
import './ContactForm.scss';

interface Alert {
  show: Boolean;
  type: NotificationType;
  text: string;
}

function ContactForm() {
  const [alert, setAlert] = useState<Alert>({
    show: false,
    type: NotificationType.Success,
    text: '',
  });

  return (
    <div id="contact-form">
      <Formik
        initialValues={{
          type: 'contact',
          name: '',
          email: '',
          telephone: '',
          location: '',
          subject: '',
          message: '',
        }}
        validationSchema={object().shape({
          name: string().required(),
          email: string().email().required(),
          telephone: string(),
          subject: string().required(),
          location: string().required(),
          message: string().required(),
        })}
        onSubmit={(values, { setSubmitting, resetForm }) => {
          sendMail({
            to: process.env.REACT_APP_FORM_RECIPIENT as string,
            from: 'OilDrop Website <noreply@oildrop.co.za>',
            subject: `[OilDrop Contact Form] ${values.subject}`,
            body: values,
          })
            .then(() => {
              setAlert({
                show: true,
                type: NotificationType.Success,
                text: 'Success! Your message has been succesfully sent, we will get back to you as soon as possible.',
              });
              setSubmitting(false);
              resetForm();
            })
            .catch(() => {
              setAlert({
                show: true,
                type: NotificationType.Error,
                text: 'Whoops! There was a problem sending your message, please try again. If the problem persists, please contact us at one of the branch emails listed on this page.',
              });
              setSubmitting(false);
            });
        }}
      >
        {({ isSubmitting }) => (
          <Form name="contact" className="form">
            <div className="form-header">
              <div className="icon">
                <FontAwesomeIcon icon={faEnvelopeOpen} />
              </div>
              <p>
                Whether you have a question about our features, service pricing
                or anything else our team is ready to answer all your questions.
              </p>
            </div>
            <div className="form-content">
              <Notification
                show={alert.show}
                type={alert.type}
                text={alert.text}
                onDismiss={() =>
                  setAlert({
                    show: false,
                    type: NotificationType.Success,
                    text: '',
                  })
                }
              />
              <div className="columns is-multiline">
                <div className="column is-full">
                  <Field
                    type="text"
                    name="name"
                    className="input is-medium"
                    placeholder="Full Name"
                  />
                  <span className="form-error">
                    <ErrorMessage name="name" />
                  </span>
                </div>
                <div className="column is-half">
                  <Field
                    type="email"
                    name="email"
                    className="input is-medium"
                    placeholder="Email"
                  />
                  <span className="form-error">
                    <ErrorMessage name="email" />
                  </span>
                </div>
                <div className="column is-half">
                  <Field
                    type="tel"
                    name="telephone"
                    className="input is-medium"
                    placeholder="Telephone"
                  />
                  <span className="form-error">
                    <ErrorMessage name="telephone" />
                  </span>
                </div>
                <div className="column is-full">
                  <Field
                    type="text"
                    name="location"
                    className=" input is-medium"
                    placeholder="Location"
                  />
                  <span className="form-error">
                    <ErrorMessage name="location" />
                  </span>
                </div>
                <div className="column is-full">
                  <Field
                    type="text"
                    name="subject"
                    className="input is-medium"
                    placeholder="Subject"
                  />
                  <span className="form-error">
                    <ErrorMessage name="subject" />
                  </span>
                </div>
                <div className="column is-full">
                  <Field
                    as="textarea"
                    name="message"
                    className="input is-medium"
                    placeholder="Message"
                    style={{ height: '150px' }}
                  />
                  <span className="form-error">
                    <ErrorMessage name="message" />
                  </span>
                </div>
                <div className="column is-full">
                  <button
                    type="submit"
                    className={`button is-medium is-fullwidth ${
                      isSubmitting ? 'is-loading' : ''
                    }`}
                  >
                    Send Message
                  </button>
                </div>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default ContactForm;
