import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGlobeAfrica } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import './Mission.scss';

const items = [
  {
    title: 'Eco-Friendly',
    text:
      'All our services and processess are perfomed to the highest standards in accordance with environmental rules and regulations.',
  },
  {
    title: 'Health & Safety',
    text:
      'We aporoach Health and Safety with due diligence and integrity. Stringent adherence to legislation and protocols is a top priority.',
  },
  {
    title: 'Cost Efficiency',
    text:
      'We are committed to for planning and executing the most cost effective methods of delivering the best possible service.',
  },
  {
    title: 'Service Delivery',
    text:
      'Our teams are assisted by progressive, state-of-the-art technology for the express purpose of top notch service delivery.',
  },
];

function Mission() {
  const missionItems = items.map((item) => {
    return (
      <div className="column is-half mission" key={item.title}>
        <div className="icon">
          <FontAwesomeIcon icon={faGlobeAfrica} />
        </div>
        <div className="text">
          <h3>{item.title}</h3>
          <p>{item.text}</p>
        </div>
      </div>
    );
  });
  return (
    <div id="missions">
      <div className="container">
        <div className="columns">
          <div className="column is-one-third">
            <h2 className="section-title">Our Mission</h2>
            <p>
              We are conscious believers in being a part of the solution,
              committed to reducing the global carbon footprint and invested in
              assisting our clients to do the same.
            </p>
            <Link to="/contact" role="button" className="button btn">
              Ask a question
            </Link>
          </div>
          <div className="column is-two-thirds">
            <div className="columns is-multiline">{missionItems}</div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Mission;
