import React, { useState } from 'react';
import moment from 'moment';
import { useBooking } from '../../BookingContext';
import { sendMail } from 'services/Mail';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCalendarAlt,
  faEnvelope,
  faComment,
} from '@fortawesome/free-regular-svg-icons';
import {
  faMapMarkerAlt,
  faUser,
  faPhoneAlt,
} from '@fortawesome/free-solid-svg-icons';
import Notification, { NotificationType } from 'components/Notification';
import './BookingConfirmation.scss';

interface Alert {
  show: Boolean;
  type: NotificationType;
  text: string;
}

function BookingConfirmation() {
  const [isSubmitting, setSubmitting] = useState<Boolean>(false);
  const [alert, setAlert] = useState<Alert>({
    show: false,
    type: NotificationType.Success,
    text: '',
  });
  const [{ data }, dispatch] = useBooking();

  const handleConfirmation = () => {
    const body = {
      type: 'booking',
      name: `${data.firstName} ${data.lastName}`,
      email: data.email!,
      telephone: data.telephone!,
      message: data.message!,
      date: moment(data.date).format('DD MMMM, YYYY'),
      location: data.location,
    };
    setSubmitting(true);

    sendMail({
      to: process.env.REACT_APP_BOOKING_RECIPIENT as string,
      from: 'OilDrop Website <noreply@oildrop.co.za>',
      subject: '[OilDrop Booking] New Booking Request',
      body,
    })
      .then(() => {
        setSubmitting(false);
        dispatch({ type: 'INCREMENT_STEP' });
      })
      .catch(() => {
        setAlert({
          show: true,
          type: NotificationType.Error,
          text: 'Whoops! There was a problem requesting a booking, please try again. If the problem persists, please contact us at one of the branch details listed on the contact page.',
        });
        setSubmitting(false);
        dispatch({ type: 'INCREMENT_STEP' });
      });
  };

  return (
    <div id="booking-confirmation">
      <Notification
        show={alert.show}
        type={alert.type}
        text={alert.text}
        onDismiss={() =>
          setAlert({
            show: false,
            type: NotificationType.Success,
            text: '',
          })
        }
      />
      <h4 className="title is-5">Confirm Collection Details</h4>
      <p>
        In order for us to provide you with our top notch service, kindly
        confirm the details below are correct before submitting.
      </p>
      <ul>
        <li>
          <FontAwesomeIcon icon={faUser} />
          {data.firstName} {data.lastName}
        </li>
        <li>
          <FontAwesomeIcon icon={faEnvelope} />
          {data.email}
        </li>
        <li>
          <FontAwesomeIcon icon={faPhoneAlt} />
          {data.telephone}
        </li>
        {data.message && (
          <li>
            <FontAwesomeIcon icon={faComment} />
            {data.message}
          </li>
        )}
        <hr />
        <li>
          <FontAwesomeIcon icon={faMapMarkerAlt} />
          {data.location}
        </li>
        <li>
          <FontAwesomeIcon icon={faCalendarAlt} />
          {moment(data.date).format('DD MMMM, YYYY')}
        </li>
      </ul>
      <hr />
      <div className="buttons">
        <button
          className="button is-outlined"
          onClick={() => dispatch({ type: 'DECREMENT_STEP' })}
        >
          Go Back
        </button>
        <div className="flex-fill"></div>
        <button
          className={`button is-oildrop ${isSubmitting ? 'is-loading' : ''}`}
          onClick={handleConfirmation}
        >
          Confirm
        </button>
      </div>
    </div>
  );
}

export default BookingConfirmation;
