import React, { useEffect, useRef, useState } from 'react';
import { useBooking } from '../../BookingContext';
import { object, string } from 'yup';
import Buttons from '../BookingStepperButtons';
import mapboxgl from 'mapbox-gl';
import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';
import '@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css';
import './BookingLocationForm.scss';

interface GeocodeResult {
  result: { place_name: string };
}

function BookingLocationForm() {
  const [validationError, setValidationError] = useState<string[]>([]);
  const mapRef = useRef<HTMLDivElement>(null);

  const [state, dispatch] = useBooking();
  const [storedLocation] = useState<string | undefined>(state.data.location);

  const validationSchema = object().shape({
    location: string().required('Please enter your location before continuing'),
  });

  useEffect(() => {
    if (mapRef.current) {
      mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN || '';
      var map = new mapboxgl.Map({
        container: mapRef.current,
        style: 'mapbox://styles/mapbox/dark-v10',
        center: [24.6727135, -28.4792625],
        zoom: 5,
      });
      const geocoder = new MapboxGeocoder({
        accessToken: process.env.REACT_APP_MAPBOX_ACCESS_TOKEN,
        mapboxgl: mapboxgl,
        countries: 'ZA',
        placeholder: 'Enter Your Location',
      });
      geocoder.on('result', (res: GeocodeResult) => {
        setValidationError([]);
        dispatch({
          type: 'SET_DATA',
          payload: { location: res.result.place_name },
        });
      });
      map.addControl(geocoder, 'top-left');
      if (storedLocation) geocoder.setInput(storedLocation);
    }
  }, [dispatch, storedLocation]);

  const validate = async () => {
    setValidationError([]);
    try {
      await validationSchema.validate(state.data);
    } catch (err) {
      if (err instanceof Error) {
        setValidationError([err.message]);
      }
      return false;
    }
    return true;
  };

  return (
    <div id="booking-location-form">
      <h3 className="mobile-title">Collection Location</h3>
      <div className="map" ref={mapRef}></div>
      <hr />
      <Buttons
        validationErrors={validationError}
        validationHandler={validate}
      />
    </div>
  );
}

export default BookingLocationForm;
