import React from 'react';
import FastSVG from 'assets/svg/fast.svg';
import ReliableSVG from 'assets/svg/reliable.svg';
import SanitizeSVG from 'assets/svg/sanitize.svg';
import EcoFriendlySVG from 'assets/svg/eco-friendly.svg';
import './ServiceIcons.scss';

const icons = [
  { title: 'Fast', icon: FastSVG },
  { title: 'Reliable', icon: ReliableSVG },
  { title: 'Safety First', icon: SanitizeSVG },
  { title: 'Eco Friendly', icon: EcoFriendlySVG },
];

function ServiceIcons() {
  const columns = icons.map((col, idx) => (
    <div className="column is-one-quarter" key={idx}>
      <img src={col.icon} alt={col.title} />
      <p className="title is-5">{col.title}</p>
    </div>
  ));

  return (
    <div id="service-icons">
      <div className="container">
        <div className="columns">{columns}</div>
      </div>
    </div>
  );
}

export default ServiceIcons;
