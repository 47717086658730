import React from 'react';
import { useBooking } from '../../BookingContext';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { object, string } from 'yup';
import Buttons from '../BookingStepperButtons';
import './BookingDetailsForm.scss';

interface FormData {
  firstName: string;
  lastName: string;
  email: string;
  telephone: string;
  message: string;
}

function BookingDetailsForm() {
  const [state, dispatch] = useBooking();

  const validate = async (
    values: FormData,
    submitForm: () => Promise<void>,
    formikValidate: (values?: any) => Promise<any>,
  ) => {
    let res: { string: string } = await formikValidate(values);
    let errors = Object.values(res);
    if (errors.length <= 0) {
      dispatch({ type: 'SET_DATA', payload: { ...values } });
      return true;
    } else {
      await submitForm();
      return false;
    }
  };

  return (
    <div id="booking-details-form">
      <h3 className="mobile-title">Company Details</h3>
      <Formik
        initialValues={{
          firstName: state.data.firstName || '',
          lastName: state.data.lastName || '',
          email: state.data.email || '',
          telephone: state.data.telephone || '',
          message: state.data.message || '',
        }}
        validationSchema={object().shape({
          firstName: string().required('Name is required').min(3),
          lastName: string().required('Surname is required').min(3),
          email: string()
            .email('Must be valid Email')
            .required('Email is required'),
          telephone: string().required('Phone number is required'),
          message: string(),
        })}
        onSubmit={() => {}}
      >
        {({ submitForm, values, validateForm }) => (
          <Form className="form">
            <div className="columns is-multiline">
              <div className="column is-half">
                <Field
                  type="text"
                  name="firstName"
                  className="input"
                  placeholder="Name"
                />
                <span className="form-error">
                  <ErrorMessage name="firstName" />
                </span>
              </div>
              <div className="column is-half">
                <Field
                  type="text"
                  name="lastName"
                  className="input"
                  placeholder="Surname"
                />
                <span className="form-error">
                  <ErrorMessage name="lastName" />
                </span>
              </div>
              <div className="column is-half">
                <Field
                  type="email"
                  name="email"
                  className="input"
                  placeholder="Email"
                />
                <span className="form-error">
                  <ErrorMessage name="email" />
                </span>
              </div>
              <div className="column is-half">
                <Field
                  type="tel"
                  name="telephone"
                  className="input"
                  placeholder="Phone"
                />
                <span className="form-error">
                  <ErrorMessage name="telephone" />
                </span>
              </div>
              <div className="column is-full">
                <Field
                  as="textarea"
                  name="message"
                  className="textarea"
                  placeholder="Message (Optional)"
                />
                <span className="form-error">
                  <ErrorMessage name="message" />
                </span>
              </div>
            </div>
            <hr />
            <Buttons
              validationHandler={() =>
                validate(values, submitForm, validateForm)
              }
            />
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default BookingDetailsForm;
