import React from 'react';
import { BookingProvider } from './BookingContext';
import ScrollToTop from 'components/ScrollToTop';
import BookingSteps from './components/BookingSteps';
import BookingStepper from './components/BookingStepper';
import PageHeader from 'components/PageHeader';
import './Booking.scss';

function Booking() {
  return (
    <div id="booking">
      <ScrollToTop />
      <PageHeader
        title="Book a Collection"
        subtitle="Experience OilDrop's first class service"
      />
      <div className="container">
        <div className="columns">
          <BookingProvider>
            <div className="column is-one-third">
              <BookingSteps />
            </div>
            <div className="column is-two-thirds">
              <BookingStepper />
            </div>
          </BookingProvider>
        </div>
      </div>
    </div>
  );
}

export default Booking;
