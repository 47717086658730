import React from 'react';
import { RecentPostsLoader } from 'components/ContentLoaders';
import { usePosts } from 'services/Posts';
import RecentPost from './components/RecentPost';
import './RecentPosts.scss';

function RecentPosts() {
  const service = usePosts(3);

  let loader = [...Array(3)].map((_, idx) => (
    <div className="column is-one-third" key={idx}>
      <RecentPostsLoader />
    </div>
  ));

  let posts =
    service.status === 'loaded' &&
    service.payload.map((post, idx) => {
      return (
        <RecentPost
          key={idx}
          title={post.title}
          image={process.env.REACT_APP_API_URL + post.thumbnail.url}
          date={post.createdAt}
          content={post.content}
          slug={post.slug}
        />
      );
    });

  return (
    <div id="posts">
      <div className="container">
        <div className="heading">
          <h2 className="section-title center">Recent Blog Posts</h2>
          <p className="byline">
            Stay up to date with OilDrops International's latest news and
            projects.
          </p>
        </div>
        <div className="columns is-multiline">
          {service.status === 'loading' && loader}
          {service.status === 'loaded' && posts}
        </div>
      </div>
    </div>
  );
}

export default RecentPosts;
