import React from 'react';
import { Link } from 'react-router-dom';
import './Callout.scss';

function Callout() {
  return (
    <div id="callout">
      <div className="container">
        <h2 className="section-title center">
          Book Your Oil Collection and Disposal
        </h2>
        <p className="byline">
          OilDrop International stands ready to collect your UCO today,
          tomorrow, together.
        </p>
        <Link to="/booking" role="button" className="button is-oildrop sm-text">
          Book a Collection
        </Link>
      </div>
    </div>
  );
}

export default Callout;
