import React, { useEffect, useState } from 'react';

export enum NotificationType {
  Success = 'is-success',
  Error = 'is-danger',
}

function Notification({
  show,
  type,
  text,
  onDismiss,
}: {
  show: Boolean;
  type: NotificationType;
  text: string;
  onDismiss: () => void;
}) {
  const [showNotification, setShowNotification] = useState<Boolean>(false);

  useEffect(() => {
    setShowNotification(show);
  }, [show]);

  const handleDismiss = () => {
    setShowNotification(false);
    onDismiss();
  };

  return showNotification ? (
    <div className={`notification ${type}`}>
      <button type="button" className="delete" onClick={handleDismiss}></button>
      {text}
    </div>
  ) : null;
}

export default Notification;
