import React from 'react';
import { Link } from 'react-router-dom';
import Logo from 'assets/images/logo-w.png';
import './CallToAction.scss';

function CallToAction() {
  return (
    <div id="call-to-action">
      <div className="container">
        <img src={Logo} alt="OilDrop" />
        <h2 className="title is-3">Book a Collection with OilDrop Today!</h2>
        <p>Quick and easy to get started.</p>
        <Link to="/booking" role="button" className="button is-oildrop sm-text">
          Book a Collection
        </Link>
      </div>
    </div>
  );
}

export default CallToAction;
