import React from 'react';
import { useBooking } from '../../BookingContext';
import './BookingStepperButtons.scss';

interface BookingStepperButtonsProps {
  validationErrors?: string[];
  validationHandler: () => Promise<boolean>;
}

enum Step {
  Increment = 'INCREMENT_STEP',
  Decrement = 'DECREMENT_STEP',
}

function BookingStepperButtons({
  validationErrors,
  validationHandler,
}: BookingStepperButtonsProps) {
  const [{ step }, dispatch] = useBooking();

  const handleClick = async (action: Step) => {
    if (action === Step.Decrement || (await validationHandler())) {
      dispatch({ type: action });
    }
  };

  const errors =
    validationErrors &&
    validationErrors.map((error, idx) => <li key={idx}>{error}</li>);

  return (
    <div id="booking-stepper-buttons" className="buttons">
      <ul className="validation-errors">{errors}</ul>
      {step > 1 && (
        <button
          type="button"
          className="button is-outlined"
          onClick={() => handleClick(Step.Decrement)}
        >
          Go Back
        </button>
      )}
      <button
        type="button"
        className="button is-oildrop"
        onClick={() => handleClick(Step.Increment)}
      >
        Continue
      </button>
    </div>
  );
}

export default BookingStepperButtons;
