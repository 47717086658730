import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faFacebookF,
  faTwitter,
  faYoutube,
  faLinkedinIn,
} from '@fortawesome/free-brands-svg-icons';
import './SocialIcons.scss';

function SocialIcons() {
  return (
    <ul id="social-icons">
      <li>
        <a href="https://facebook.com">
          <FontAwesomeIcon icon={faFacebookF} />
        </a>
      </li>
      <li>
        <a href="https://twitter.com">
          <FontAwesomeIcon icon={faTwitter} />
        </a>
      </li>
      <li>
        <a href="https://youtube.com">
          <FontAwesomeIcon icon={faYoutube} />
        </a>
      </li>
      <li>
        <a href="https://linkedin.com">
          <FontAwesomeIcon icon={faLinkedinIn} />
        </a>
      </li>
    </ul>
  );
}

export default SocialIcons;
