import React from 'react';
import OramaLogo from 'assets/images/orama.jpg';
import './Testimonials.scss';

interface TestimonialsProps {
  isLight?: boolean;
}

function Testimonials({ isLight }: TestimonialsProps) {
  return (
    <div id="testimonials" className={isLight ? 'is-light' : ''}>
      <div className="container">
        <div className="portrait">
          <img src={OramaLogo} alt="Orama Roadhouse" />
        </div>
        <p className="testimonial">
          OilDrop International have provided a consistently good and
          professional service to my business for 10 years now. I would
          recommend their service to any companies in the food industry.
        </p>
        <h3 className="name">Peter</h3>
        <p className="job-title">Owner - Orama Roadhouse</p>
      </div>
    </div>
  );
}

export default Testimonials;
