import React from 'react';
import './PageHeader.scss';

interface PageHeaderProps {
  title: String;
  subtitle: String;
}

function PageHeader({ title, subtitle }: PageHeaderProps) {
  return (
    <div id="page-header">
      <div className="container">
        <h2>{title}</h2>
        <h5>{subtitle}</h5>
      </div>
    </div>
  );
}

export default PageHeader;
