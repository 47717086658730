import React, { useState } from 'react';
import { useBooking } from '../../BookingContext';
import { object, bool, date } from 'yup';
import Flatpickr from 'react-flatpickr';
import BookingStepperButtons from '../BookingStepperButtons';
import './BookingDateForm.scss';

function BookingDateForm() {
  const [confirm, setConfirm] = useState(false);
  const [validationErrors, setValidationErrors] = useState<string[]>([]);
  const [state, dispatch] = useBooking();

  const validationSchema = object().shape({
    date: date().required('Date is required'),
    confirm: bool()
      .oneOf([true], 'Please confirm that you understand our terms')
      .required('Confirmation is required'),
  });

  const validate = async () => {
    setValidationErrors([]);
    try {
      await validationSchema.validate({ ...state.data, confirm });
    } catch (err) {
      if (err instanceof Error) {
        setValidationErrors([err.message]);
      }
      return false;
    }
    return true;
  };

  return (
    <div id="booking-date-form">
      <h3 className="mobile-title">Collection Date</h3>
      <div className="columns">
        <div className="column is-two-fifths">
          <Flatpickr
            options={{
              inline: true,
              minDate: Date.now() + 172800000, // 48 hours
              defaultDate: state.data.date,
            }}
            className="input is-hidden"
            onChange={(date) =>
              dispatch({ type: 'SET_DATA', payload: { date: date[0] } })
            }
            placeholder="Select Preferred Date"
          />
        </div>
        <div className="column is-three-fifth">
          <h4 className="title is-5">Please Note</h4>
          <p>
            We value your business and offer a 48 hour turn around time on all
            UCO collections.
          </p>
          <h5 className="title is-5">Emergency Collections</h5>
          <p>
            24 hour collection service can be arranged by calling our head
            office directly. a Nominal fee might be applicable in certain
            circumstances.
          </p>
          <label className="checkbox">
            <input
              type="checkbox"
              name="confirm"
              onChange={(e) => setConfirm(e.target.checked)}
            />{' '}
            <span className="check"></span>I Understand
          </label>
        </div>
      </div>
      <hr />
      <BookingStepperButtons
        validationErrors={validationErrors}
        validationHandler={validate}
      />
    </div>
  );
}

export default BookingDateForm;
