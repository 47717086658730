import React from 'react';
import { Link } from 'react-router-dom';
import './Masthead.scss';
import ContactBlocks from './components/ContactBlocks';

function Masthead() {
  return (
    <div id="masthead">
      <div className="container">
        <h1 className="section-title center">Environmental Solutions</h1>
        <p className="byline">
          The environmentally focussed company committed to reducing the global
          carbon footprint and invested in assisting our clients to do the same.
        </p>
        <Link to="/about" role="button" className="button btn">
          Find out more
        </Link>
      </div>
      <ContactBlocks />
    </div>
  );
}

export default Masthead;
