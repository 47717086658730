import React from 'react';
import { useServices } from 'services/data';
import './OurServices.scss';

function Services() {
  const services = useServices();

  let columns = services.map((service) => {
    return (
      <div className="column is-one-third service" key={service.title}>
        <figure className="image is-48x48">
          <img src={service.icon} alt={service.title} />
        </figure>
        <h3>{service.title}</h3>
        <p>{service.description}</p>
      </div>
    );
  });

  return (
    <div id="our-services">
      <div className="container">
        <div className="heading">
          <h2 className="section-title center">Our Services</h2>
          <p className="byline">
            OilDrop International aims to offer the best services in the
            industry at the most reasonable prices, while fervently pursuing our
            vision of being Eco Friendly for preservation of our environment for
            future genetations.
          </p>
        </div>
        <div className="columns is-multiline service-grid">{columns}</div>
      </div>
    </div>
  );
}

export default Services;
