import React from 'react';
import GalleryVehicle from 'assets/images/gallery/OilDrop-Collection-Vehicle.jpg';
import GalleryBarrels from 'assets/images/gallery/OilDrop-150Litre-Barrels.jpg';
import GalleryTank from 'assets/images/gallery/OilDrop-Tank.jpg';
import GalleryISOTank from 'assets/images/gallery/OilDrop-Iso-Tank.jpg';
import GalleryHondaPump from 'assets/images/gallery/OilDrop-Honda-Pump.jpg';
import GalleryBuckets from 'assets/images/gallery/OilDrop-20Litre-Buckets.jpg';
import './Gallery.scss';

function Gallery() {
  return (
    <div id="gallery">
      <div className="columns is-gapless">
        <div className="column is-two-quarters">
          <div className="columns is-multiline is-gapless">
            <div className="column is-half">
              <figure className="image">
                <img
                  src={GalleryVehicle}
                  alt="Used Cooking Oil - Collection Vehicle"
                />
              </figure>
            </div>
            <div className="column is-half">
              <figure className="image">
                <img
                  src={GalleryBarrels}
                  alt="Used Cooking Oil - 150 Litre Barrels"
                />
              </figure>
            </div>
            <div className="column is-full">
              <figure className="image two-col">
                <img src={GalleryTank} alt="Used Cooking Oil - Tank" />
              </figure>
            </div>
          </div>
        </div>
        <div className="column is-one-quarter">
          <figure className="image two-row">
            <img src={GalleryISOTank} alt="OilDrop Iso Tank" />
          </figure>
        </div>
        <div className="column is-one-quarter">
          <div className="columns is-multiline is-gapless">
            <div className="column is-full">
              <figure className="image">
                <img
                  src={GalleryHondaPump}
                  alt="Used Cooking Oil - Honda Pump"
                />
              </figure>
            </div>
            <div className="column is-full">
              <figure className="image">
                <img
                  src={GalleryBuckets}
                  alt="Used Cooking Oil - 20 Litre Buckets"
                />
              </figure>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Gallery;
