import React from 'react';
import './AboutOilDrop.scss';

function AboutOilDrop() {
  return (
    <div id="about-oildrop">
      <div className="container limited">
        <h2 className="section-title center">About OilDrop</h2>
        <p>
          <b>
            Oil Drop South Africa is an innovative company that specialises in
            the Effective Management and Collection of Used Cooking Oil (UCO) in
            the safest and most environmentally friendly manner.
          </b>
        </p>
        <p>
          Our Research and Development team has designed a UCO Management System
          for handling the hot oil which will be pumped into an external,
          volumetric tank, directly from the fryers. We are proud to acknowledge
          that we are intent on adhering to all the industry legislation, rules,
          and regulations pertaining to this industry. In fact, we fully intend
          to add a few too.
        </p>
      </div>
    </div>
  );
}

export default AboutOilDrop;
