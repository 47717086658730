import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Logo from 'assets/images/logo-w.png';
import './Header.scss';

const menuItems = [
  { title: 'Home', link: '/' },
  { title: 'About', link: '/about' },
  { title: 'Services', link: '/services' },
  { title: 'Blog', link: '/blog' },
  { title: 'Franchising', link: '/files/franchising.pdf' },
  { title: 'Contact', link: '/contact' },
];

function Header() {
  const [showMobileMenu, setShowMobileMenu] = useState(false);
  const navigate = useNavigate();

  const handleClick = (link: string) => {
    if (link.includes('.pdf')) {
      window.open(link, '_blank');
    } else {
      navigate(link);
    }
    setShowMobileMenu(false);
  };

  const menu = menuItems.map((item) => (
    <li key={item.title}>
      <Link
        to={item.link}
        target={item.link.includes('.pdf') ? '_blank' : '_self'}
      >
        {item.title}
      </Link>
    </li>
  ));

  const mobiMenu = menuItems.map((item) => (
    <li key={item.title}>
      <button onClick={() => handleClick(item.link)}>{item.title}</button>
    </li>
  ));

  return (
    <div id="header">
      <nav className="main">
        <div className="container">
          <div className="logo">
            <Link to="/">
              <img src={Logo} alt="OilDrop International" />
            </Link>
          </div>
          <button
            className="navbar-burger"
            aria-label="menu"
            aria-expanded="false"
            onClick={() => setShowMobileMenu(!showMobileMenu)}
          >
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
          </button>
          <ul className="main-menu">{menu}</ul>
          <Link
            to="/booking"
            role="button"
            className="button is-white is-outlined"
          >
            Book a Collection
          </Link>
        </div>
      </nav>
      {showMobileMenu && (
        <nav className="mobile-menu">
          <ul>
            {mobiMenu}
            <li>
              <button onClick={() => handleClick('/booking')}>
                Book a Collection
              </button>
            </li>
          </ul>
        </nav>
      )}
    </div>
  );
}

export default Header;
