import React from 'react';
import LocationForm from '../BookingLocationForm';
import DetailsForm from '../BookingDetailsForm';
import DateForm from '../BookingDateForm';
import Confirmation from '../BookingConfirmation';
import Complete from '../BookingComplete';
import { useBooking } from '../../BookingContext';
import './BookingStepper.scss';

function BookingStepper() {
  const [state] = useBooking();

  const steps = [
    <LocationForm />,
    <DetailsForm />,
    <DateForm />,
    <Confirmation />,
    <Complete />,
  ];

  return <div id="booking-stepper">{steps[state.step - 1]}</div>;
}

export default BookingStepper;
