import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faClock,
  faMapMarkerAlt,
  faPhoneAlt,
} from '@fortawesome/free-solid-svg-icons';
import './ContactBlocks.scss';

function ContactBlocks() {
  return (
    <div id="contact-blocks">
      <div className="container">
        <div className="cblock contact">
          <div className="icon">
            <FontAwesomeIcon icon={faPhoneAlt} />
          </div>
          <h3>Contact Us</h3>
          <div className="text">
            <strong>Phone:</strong> <a href="tel:0110256770">011 025 6770</a>
            <br />
            <strong>Email:</strong>{' '}
            <a href="mailto:info@oildrop.co.za">info@oildrop.co.za</a>
          </div>
        </div>
        <div className="cblock address">
          <div className="icon">
            <FontAwesomeIcon icon={faMapMarkerAlt} />
          </div>
          <h3>Our Address</h3>
          <div className="text">
            <strong>Head Office:</strong>
            <address>
              25 Leicester Street, Benoni South
              <br />
              Benoni, Gauteng, 1501
            </address>
          </div>
        </div>
        <div className="cblock hours">
          <div className="icon">
            <FontAwesomeIcon icon={faClock} />
          </div>
          <h3>Working Hours</h3>
          <div className="text">
            <strong>Monday - Friday:</strong> 8:00am to 6:00pm
            <br />
            <strong>Saturday, Sunday:</strong> 8:00am to 3:00pm
          </div>
        </div>
      </div>
    </div>
  );
}

export default ContactBlocks;
