import UCOCollection from 'assets/images/uco-collection.jpg';
import OilDropOffs from 'assets/images/new-oil-drop-offs.jpg';
import UCOTechnology from 'assets/images/uco-management-technology.jpg';
import ServiceIcon from 'assets/images/service.png';

interface Services {
  image: string;
  icon: string;
  title: string;
  description: string;
}

const services: Services[] = [
  {
    image: UCOCollection,
    icon: ServiceIcon,
    title: 'Used Cooking Oil Collections',
    description:
      'We provide a professional used cooking oil collection service that provides our clients with the peace of mind that their oil has entered its final phase in becoming a valuable renewable resource.',
  },
  {
    image: OilDropOffs,
    icon: ServiceIcon,
    title: 'New Oil Drop-offs',
    description:
      'We have managed to source the finest quality, locally produced Sunflower and Canola Oils for your convenience. We will supply and deliver in a variety of sizes at the most competitive prices.',
  },
  {
    image: UCOTechnology,
    icon: ServiceIcon,
    title: 'UCO Management Technology',
    description:
      'We achieve outstanding results in managing the process of the collection of UCO by making use of the most innovative management and reporting infrastructure in the industry.',
  },
];

export function useServices() {
  return services;
}
