import React from 'react';
import { Link } from 'react-router-dom';
import Logo from 'assets/images/logo-w.png';
import './Footer.scss';

function Footer() {
  let date = new Date();
  let currentYear = date.getFullYear();
  return (
    <div id="footer">
      <div className="main">
        <div className="container">
          <div className="columns">
            <div className="column is-one-third logo">
              <img src={Logo} alt="Oil Drop South Africa" />
              <p>
                Oil Drop South Africa is an Innovative company that specialises
                in the Effective Management and Collection of Used Cooking Oil
                (UCO) in the safest and most environmentally friendly manner.
              </p>
            </div>
            <div className="column is-one-third">
              <h3>Useful Links</h3>
              <ul className="links">
                <li>
                  <Link to="/">Home</Link>
                </li>
                <li>
                  <Link to="/services">Services</Link>
                </li>
                <li>
                  <Link to="/about">About</Link>
                </li>
                <li>
                  <Link to="/blog">Blog</Link>
                </li>
                <li>
                  <Link to="/contact">Contact</Link>
                </li>
                <li>
                  <Link to="/files/franchising.pdf" target="_blank">
                    Franchising
                  </Link>
                </li>
              </ul>
            </div>
            <div className="column is-one-third">
              <h3>Book Collection</h3>
              <p>
                OilDrop International stands ready to collect your UCO today,
                tomorrow, together.
              </p>
              <Link
                to="/booking"
                role="button"
                className="button is-oildrop sm-text"
              >
                Book a collection
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className="meta">
        <div className="container">
          <p>&copy; {currentYear} | Oil Drop South Africa</p>
          <div className="plug">
            Developed with
            <span role="img" aria-label="love">
              &#128154;
            </span>
            by{' '}
            <a href="http://trademarktechnology.co.za">Trademark Technology</a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;
