import React from 'react';
import ScrollToTop from 'components/ScrollToTop';
import PageHeader from 'components/PageHeader';
import ServiceColumns from './components/ServiceColumns';
import ServiceIcons from './components/ServiceIcons';
import ServiceSeparator from './components/ServiceSeparator';
import Testimonials from 'components/Testimonials';
import CallToAction from 'components/CallToAction';
import './Services.scss';

function Services() {
  return (
    <div id="services">
      <ScrollToTop />
      <PageHeader title="Services" subtitle="What OilDrop can do for you" />
      <ServiceColumns />
      <ServiceIcons />
      <ServiceSeparator />
      <Testimonials isLight={true} />
      <CallToAction />
    </div>
  );
}

export default Services;
