import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import Header from 'components/Header';
import Footer from 'components/Footer';

import Home from 'scenes/Home';
import About from 'scenes/About';
import Booking from 'scenes/Booking';
import Services from 'scenes/Services';
import Blog from 'scenes/Blog';
import BlogPost from 'scenes/BlogPost';
import Contact from 'scenes/Contact';

function App() {
  return (
    <Router>
      <div className="App">
        <Header />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/booking" element={<Booking />} />
          <Route path="/services" element={<Services />} />
          <Route path="/blog" element={<Blog />} />
          <Route path="/blog/:slug" element={<BlogPost />} />
          <Route path="/contact" element={<Contact />} />
        </Routes>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
