import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useBooking } from '../../BookingContext';

function BookingComplete() {
  const [, dispatch] = useBooking();

  useEffect(() => {
    dispatch({ type: 'CLEAR_DATA' });
  }, [dispatch]);

  return (
    <div id="booking-complete">
      <h4 className="title is-5">Collection Booking Received</h4>
      <p>
        Thank you for submitting an oil collection request to OilDrop. A member
        of our team will be in contact with you shortly to finalize the details
        of your collection.
      </p>
      <hr />
      <p>
        If you have any further queries, please don't hesitate to contact us
        using the form or details listed on our{' '}
        <Link to="/contact">contact page</Link>.
      </p>
    </div>
  );
}

export default BookingComplete;
