import { useEffect, useState } from 'react';
import { Post } from 'types/Post';
import { Service } from 'types/Service';

const apiUrl = process.env.REACT_APP_API_URL;

export const usePosts = (limit: number = 10) => {
  const [result, setResult] = useState<Service<Post[]>>({
    status: 'loading',
  });

  useEffect(() => {
    fetch(`${apiUrl}/articles?_limit=${limit}`)
      .then((res) => res.json())
      .then((data) => setResult({ status: 'loaded', payload: data }))
      .catch((error) => setResult({ status: 'error', error }));
  }, [limit]);

  return result;
};

export const usePostBySlug = (slug: string | undefined) => {
  const [result, setResult] = useState<Service<Post>>({
    status: 'loading',
  });

  useEffect(() => {
    fetch(`${apiUrl}/articles?slug=${slug}`)
      .then((res) => res.json())
      .then((data) => setResult({ status: 'loaded', payload: data[0] }))
      .catch((error) => setResult({ status: 'error', error }));
  }, [slug]);

  return result;
};
