import React from 'react';
import { useBooking } from '../../BookingContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCheck,
  faCheckCircle,
  faAddressBook,
  faCalendarAlt,
  faMapPin,
} from '@fortawesome/free-solid-svg-icons';
import './BookingSteps.scss';

const steps = [
  {
    title: 'Collection Location',
    icon: faMapPin,
  },
  {
    title: 'Company Details',
    icon: faAddressBook,
  },
  {
    title: 'Collection Date',
    icon: faCalendarAlt,
  },
  {
    title: 'Confirm',
    icon: faCheckCircle,
  },
];

function BookingSteps() {
  const [{ step }] = useBooking();
  const stepList = steps.map((s, idx) => (
    <li
      className={`${step === idx + 1 ? 'current' : ''} ${
        step > idx + 1 ? 'completed' : ''
      }`}
      key={idx}
    >
      <div className="icon">
        <FontAwesomeIcon icon={step <= idx + 1 ? s.icon : faCheck} />
      </div>
      <div className="text-wrap">
        <p>Step {idx + 1}</p>
        <h3>{s.title}</h3>
      </div>
    </li>
  ));

  return (
    <div id="booking-steps">
      <ul>{stepList}</ul>
    </div>
  );
}

export default BookingSteps;
