import React from 'react';
import { Link } from 'react-router-dom';
import AboutImage from 'assets/images/about.png';
import './WhoWeAre.scss';

function WhoWeAre() {
  return (
    <div id="who-we-are">
      <div className="container">
        <div className="columns">
          <div className="column is-one-half">
            <figure className="image">
              <img src={AboutImage} alt="About OilDrop" />
            </figure>
          </div>
          <div className="column is-one-half">
            <h2 className="section-title">Who We Are</h2>
            <p>
              Oil Drop South Africa was founded in March 2018 by Hermann Vorster
              or Mr. V as he has been affectionately named by his staff &amp;
              colleagues. Oil Drop has become his favoured project since its
              inception and has proved to be an invaluable added division to
              Grease Traps South Africa.
            </p>
            <p>
              It was fortunate that our company was in the position of already
              having a fleet of 100 vehicles nationally so that the introduction
              of a new division could be made seemingly effortlessly. The
              advantage of this has resulted in us being able to provide highly
              competitive rates to our clients.
            </p>
            <p className="emphasize">
              At present we are the <span className="seconday">largest</span>{' '}
              collector of Used Cooking Oil{' '}
              <span className="secondary">throughout South Africa.</span>
            </p>
            <p>
              We do not take this for granted, even for a split second, so we
              remain humble, hard-working, and devoted to keeping our client's
              needs at the top of our priority list - without them, there will
              be no "us."
            </p>
            <Link to="/about" role="button" className="button btn">
              View more
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default WhoWeAre;
