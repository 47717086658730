import React, { createContext, useReducer, useContext } from 'react';

type Action =
  | { type: 'INCREMENT_STEP' }
  | { type: 'DECREMENT_STEP' }
  | { type: 'SET_DATA'; payload: {} }
  | { type: 'CLEAR_DATA' };
type Dispatch = (action: Action) => void;

interface State {
  step: number;
  data: {
    location?: string;
    firstName?: string;
    lastName?: string;
    email?: string;
    telephone?: string;
    message?: string;
    date?: Date;
  };
}

interface BookingProviderProps {
  children: React.ReactNode;
}

const BookingStateContext = createContext<State | undefined>(undefined);
const BookingDispatchContext = createContext<Dispatch | undefined>(undefined);

function bookingReducer(state: State, action: Action) {
  switch (action.type) {
    case 'INCREMENT_STEP': {
      return { ...state, step: state.step + 1 };
    }
    case 'DECREMENT_STEP': {
      return { ...state, step: state.step - 1 };
    }
    case 'SET_DATA': {
      return { ...state, data: { ...state.data, ...action.payload } };
    }
    case 'CLEAR_DATA': {
      return { ...state, data: {} };
    }
  }
}

function BookingProvider({ children }: BookingProviderProps) {
  const [state, dispatch] = useReducer(bookingReducer, { step: 1, data: {} });

  return (
    <BookingStateContext.Provider value={state}>
      <BookingDispatchContext.Provider value={dispatch}>
        {children}
      </BookingDispatchContext.Provider>
    </BookingStateContext.Provider>
  );
}

function useBookingState() {
  const context = useContext(BookingStateContext);
  if (context === undefined) {
    throw new Error('useBookingState must be used within a BookingProvider');
  }
  return context;
}

function useBookingDispatch() {
  const context = useContext(BookingDispatchContext);
  if (context === undefined) {
    throw new Error('useBookingDispatch must be used within a BookingProvider');
  }
  return context;
}

function useBooking(): [State, Dispatch] {
  return [useBookingState(), useBookingDispatch()];
}

export { BookingProvider, useBooking };
