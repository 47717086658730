import React from 'react';
import './Banner.scss';

function Banner() {
  return (
    <div id="banner">
      <div className="container">
        <h2 className="section-title center">
          Used Oil Collection Specialists
        </h2>
        <p className="byline">
          OilDrop International is ready and equipped to service all your used
          cooking oil collection requirements.
        </p>
      </div>
    </div>
  );
}

export default Banner;
