import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChevronRight,
  faChevronDown,
  faUser,
  faPhone,
  faEnvelope,
  faMapMarkerAlt,
} from '@fortawesome/free-solid-svg-icons';
import './Branch.scss';

interface BranchProps {
  name: string;
  contact: string;
  tel: string;
  email: string;
  address: string;
  show: boolean;
  onClick: () => void;
}

function Branch({
  name,
  contact,
  tel,
  email,
  address,
  show,
  onClick,
}: BranchProps) {
  return (
    <div id="branch">
      <div className="branch-header" onClick={() => onClick()}>
        <h5 className="title is-5">{name}</h5>
        <FontAwesomeIcon icon={show ? faChevronDown : faChevronRight} />
      </div>
      {show && (
        <div className="branch-content">
          <ul>
            <li>
              <div className="icon">
                <FontAwesomeIcon icon={faUser} />
              </div>
              {contact}
            </li>
            <li>
              <div className="icon">
                <FontAwesomeIcon icon={faPhone} />
              </div>
              {tel}
            </li>
            <li>
              <div className="icon">
                <FontAwesomeIcon icon={faEnvelope} />
              </div>
              {email}
            </li>
            <li>
              <div className="icon">
                <FontAwesomeIcon icon={faMapMarkerAlt} />
              </div>
              {address}
            </li>
          </ul>
        </div>
      )}
    </div>
  );
}

export default Branch;
