import React from 'react';
import ScrollToTop from 'components/ScrollToTop';

import Masthead from './components/Masthead';
import About from './components/WhoWeAre';
import Callout from './components/Callout';
import Services from './components/OurServices';
import Gallery from './components/Gallery';
import Mission from './components/Mission';
import Testimonials from 'components/Testimonials';
import RecentPosts from './components/RecentPosts';
import Partners from './components/Partners';

function Home() {
  return (
    <>
      <ScrollToTop />
      <Masthead />
      <About />
      <Callout />
      <Services />
      <Gallery />
      <Mission />
      <Testimonials />
      <RecentPosts />
      <Partners />
    </>
  );
}

export default Home;
